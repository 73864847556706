//保障服务接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-11 13:15:50
 */
// 保障服务分页查询
export function servicePageList(parameter){
    return axios({
        url: '/securityServices/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//保障服务查询所有
export function serviceList(parameter){
    return axios({
        url: '/securityServices/list',
        method: 'post',
        data: parameter
    })
}
//保障服务详情
export function serviceDetail(parameter){
    return axios({
        url: '/securityServices/detail',
        method: 'post',
        params: parameter 
    })
}
//保障服务增加
export function serviceAdd(parameter){
    return axios({
        url: '/securityServices/add',
        method: 'post',
        data: parameter
    })
}
//保障服务删除
export function serviceDelete(parameter){
    return axios({
        url: '/securityServices/delete',
        method: 'post',
        data: parameter
    })
}
//保障服务编辑
export function serviceEdit(parameter){
    return axios({
        url: '/securityServices/edit',
        method: 'post',
        data: parameter
    })
}